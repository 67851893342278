export const AccountMenuList = () => import('../../components/account/MenuList.vue' /* webpackChunkName: "components/account-menu-list" */).then(c => wrapFunctional(c.default || c))
export const AppBackdropOverlay = () => import('../../components/app/BackdropOverlay.vue' /* webpackChunkName: "components/app-backdrop-overlay" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumbs = () => import('../../components/app/Breadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppCookiePopup = () => import('../../components/app/CookiePopup.vue' /* webpackChunkName: "components/app-cookie-popup" */).then(c => wrapFunctional(c.default || c))
export const AppLayout = () => import('../../components/app/Layout.vue' /* webpackChunkName: "components/app-layout" */).then(c => wrapFunctional(c.default || c))
export const AppLazyHydrate = () => import('../../components/app/LazyHydrate.vue' /* webpackChunkName: "components/app-lazy-hydrate" */).then(c => wrapFunctional(c.default || c))
export const AppLoadingBar = () => import('../../components/app/LoadingBar.vue' /* webpackChunkName: "components/app-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const AppModal = () => import('../../components/app/Modal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c))
export const AppNoty = () => import('../../components/app/Noty.vue' /* webpackChunkName: "components/app-noty" */).then(c => wrapFunctional(c.default || c))
export const AppPageHeader = () => import('../../components/app/PageHeader.vue' /* webpackChunkName: "components/app-page-header" */).then(c => wrapFunctional(c.default || c))
export const AppPageTitle = () => import('../../components/app/PageTitle.vue' /* webpackChunkName: "components/app-page-title" */).then(c => wrapFunctional(c.default || c))
export const AppSearchPanel = () => import('../../components/app/SearchPanel.vue' /* webpackChunkName: "components/app-search-panel" */).then(c => wrapFunctional(c.default || c))
export const AppSidebar = () => import('../../components/app/Sidebar.vue' /* webpackChunkName: "components/app-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AppUSP = () => import('../../components/app/USP.vue' /* webpackChunkName: "components/app-u-s-p" */).then(c => wrapFunctional(c.default || c))
export const BaseBackdropOverlay = () => import('../../components/base/BackdropOverlay.vue' /* webpackChunkName: "components/base-backdrop-overlay" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/Button.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/base/Checkbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapse = () => import('../../components/base/Collapse.vue' /* webpackChunkName: "components/base-collapse" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseExtraOptions = () => import('../../components/base/CollapseExtraOptions.vue' /* webpackChunkName: "components/base-collapse-extra-options" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseItem = () => import('../../components/base/CollapseItem.vue' /* webpackChunkName: "components/base-collapse-item" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseOptions = () => import('../../components/base/CollapseOptions.vue' /* webpackChunkName: "components/base-collapse-options" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseTransition = () => import('../../components/base/CollapseTransition.vue' /* webpackChunkName: "components/base-collapse-transition" */).then(c => wrapFunctional(c.default || c))
export const BaseForm = () => import('../../components/base/Form.vue' /* webpackChunkName: "components/base-form" */).then(c => wrapFunctional(c.default || c))
export const BaseFormItem = () => import('../../components/base/FormItem.vue' /* webpackChunkName: "components/base-form-item" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/base/Icon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImageZoomer = () => import('../../components/base/ImageZoomer.vue' /* webpackChunkName: "components/base-image-zoomer" */).then(c => wrapFunctional(c.default || c))
export const BaseInfiniteLoading = () => import('../../components/base/InfiniteLoading.vue' /* webpackChunkName: "components/base-infinite-loading" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/base/Input.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseInputNumber = () => import('../../components/base/InputNumber.vue' /* webpackChunkName: "components/base-input-number" */).then(c => wrapFunctional(c.default || c))
export const BaseLightBox = () => import('../../components/base/LightBox.vue' /* webpackChunkName: "components/base-light-box" */).then(c => wrapFunctional(c.default || c))
export const BaseMap = () => import('../../components/base/Map.vue' /* webpackChunkName: "components/base-map" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseModalLayout = () => import('../../components/base/ModalLayout.vue' /* webpackChunkName: "components/base-modal-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseOverlay = () => import('../../components/base/Overlay.vue' /* webpackChunkName: "components/base-overlay" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/base/Pagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../components/base/Radio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const BaseRatingBar = () => import('../../components/base/RatingBar.vue' /* webpackChunkName: "components/base-rating-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseRatingBarGroup = () => import('../../components/base/RatingBarGroup.vue' /* webpackChunkName: "components/base-rating-bar-group" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/Select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseSidebarLayout = () => import('../../components/base/SidebarLayout.vue' /* webpackChunkName: "components/base-sidebar-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseStarRating = () => import('../../components/base/StarRating.vue' /* webpackChunkName: "components/base-star-rating" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/base/Textarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseToggle = () => import('../../components/base/Toggle.vue' /* webpackChunkName: "components/base-toggle" */).then(c => wrapFunctional(c.default || c))
export const BaseTouchScroll = () => import('../../components/base/TouchScroll.vue' /* webpackChunkName: "components/base-touch-scroll" */).then(c => wrapFunctional(c.default || c))
export const BaseTransitionOpacity = () => import('../../components/base/TransitionOpacity.vue' /* webpackChunkName: "components/base-transition-opacity" */).then(c => wrapFunctional(c.default || c))
export const BaseVideo = () => import('../../components/base/Video.vue' /* webpackChunkName: "components/base-video" */).then(c => wrapFunctional(c.default || c))
export const BlogArticle = () => import('../../components/blog/Article.vue' /* webpackChunkName: "components/blog-article" */).then(c => wrapFunctional(c.default || c))
export const BlogLayout = () => import('../../components/blog/Layout.vue' /* webpackChunkName: "components/blog-layout" */).then(c => wrapFunctional(c.default || c))
export const BlogNewsArticles = () => import('../../components/blog/NewsArticles.vue' /* webpackChunkName: "components/blog-news-articles" */).then(c => wrapFunctional(c.default || c))
export const BlogSideMenu = () => import('../../components/blog/SideMenu.vue' /* webpackChunkName: "components/blog-side-menu" */).then(c => wrapFunctional(c.default || c))
export const BlogTagArticles = () => import('../../components/blog/TagArticles.vue' /* webpackChunkName: "components/blog-tag-articles" */).then(c => wrapFunctional(c.default || c))
export const BrandAlphabetMegaMenu = () => import('../../components/brand/AlphabetMegaMenu.vue' /* webpackChunkName: "components/brand-alphabet-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const BrandImageItem = () => import('../../components/brand/ImageItem.vue' /* webpackChunkName: "components/brand-image-item" */).then(c => wrapFunctional(c.default || c))
export const CartActiveVouchers = () => import('../../components/cart/ActiveVouchers.vue' /* webpackChunkName: "components/cart-active-vouchers" */).then(c => wrapFunctional(c.default || c))
export const CartDeliveryPossibilities = () => import('../../components/cart/DeliveryPossibilities.vue' /* webpackChunkName: "components/cart-delivery-possibilities" */).then(c => wrapFunctional(c.default || c))
export const CartEmptyBox = () => import('../../components/cart/EmptyBox.vue' /* webpackChunkName: "components/cart-empty-box" */).then(c => wrapFunctional(c.default || c))
export const CartFreeGiftLine = () => import('../../components/cart/FreeGiftLine.vue' /* webpackChunkName: "components/cart-free-gift-line" */).then(c => wrapFunctional(c.default || c))
export const CartGiftLine = () => import('../../components/cart/GiftLine.vue' /* webpackChunkName: "components/cart-gift-line" */).then(c => wrapFunctional(c.default || c))
export const CartLine = () => import('../../components/cart/Line.vue' /* webpackChunkName: "components/cart-line" */).then(c => wrapFunctional(c.default || c))
export const CartLineNote = () => import('../../components/cart/LineNote.vue' /* webpackChunkName: "components/cart-line-note" */).then(c => wrapFunctional(c.default || c))
export const CartLines = () => import('../../components/cart/Lines.vue' /* webpackChunkName: "components/cart-lines" */).then(c => wrapFunctional(c.default || c))
export const CartPaymentVouchers = () => import('../../components/cart/PaymentVouchers.vue' /* webpackChunkName: "components/cart-payment-vouchers" */).then(c => wrapFunctional(c.default || c))
export const CartProductVouchers = () => import('../../components/cart/ProductVouchers.vue' /* webpackChunkName: "components/cart-product-vouchers" */).then(c => wrapFunctional(c.default || c))
export const CartShippingCost = () => import('../../components/cart/ShippingCost.vue' /* webpackChunkName: "components/cart-shipping-cost" */).then(c => wrapFunctional(c.default || c))
export const CartShippingEstimation = () => import('../../components/cart/ShippingEstimation.vue' /* webpackChunkName: "components/cart-shipping-estimation" */).then(c => wrapFunctional(c.default || c))
export const CartStockWarning = () => import('../../components/cart/StockWarning.vue' /* webpackChunkName: "components/cart-stock-warning" */).then(c => wrapFunctional(c.default || c))
export const CartSubmitButton = () => import('../../components/cart/SubmitButton.vue' /* webpackChunkName: "components/cart-submit-button" */).then(c => wrapFunctional(c.default || c))
export const CartTotal = () => import('../../components/cart/Total.vue' /* webpackChunkName: "components/cart-total" */).then(c => wrapFunctional(c.default || c))
export const CartUsefulBox = () => import('../../components/cart/UsefulBox.vue' /* webpackChunkName: "components/cart-useful-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartSummaryBox = () => import('../../components/checkout/CartSummaryBox.vue' /* webpackChunkName: "components/checkout-cart-summary-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDelivery = () => import('../../components/checkout/Delivery.vue' /* webpackChunkName: "components/checkout-delivery" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAlternativeAddress = () => import('../../components/checkout/DeliveryAlternativeAddress.vue' /* webpackChunkName: "components/checkout-delivery-alternative-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryMyAddress = () => import('../../components/checkout/DeliveryMyAddress.vue' /* webpackChunkName: "components/checkout-delivery-my-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutInformation = () => import('../../components/checkout/Information.vue' /* webpackChunkName: "components/checkout-information" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOverview = () => import('../../components/checkout/Overview.vue' /* webpackChunkName: "components/checkout-overview" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOverviewBox = () => import('../../components/checkout/OverviewBox.vue' /* webpackChunkName: "components/checkout-overview-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOverviewCartLine = () => import('../../components/checkout/OverviewCartLine.vue' /* webpackChunkName: "components/checkout-overview-cart-line" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOverviewCartTotal = () => import('../../components/checkout/OverviewCartTotal.vue' /* webpackChunkName: "components/checkout-overview-cart-total" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPayment = () => import('../../components/checkout/Payment..vue' /* webpackChunkName: "components/checkout-payment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentRadio = () => import('../../components/checkout/PaymentRadio.vue' /* webpackChunkName: "components/checkout-payment-radio" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupMyStore = () => import('../../components/checkout/PickupMyStore.vue' /* webpackChunkName: "components/checkout-pickup-my-store" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupOtherStore = () => import('../../components/checkout/PickupOtherStore.vue' /* webpackChunkName: "components/checkout-pickup-other-store" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSteps = () => import('../../components/checkout/Steps.vue' /* webpackChunkName: "components/checkout-steps" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSuccess = () => import('../../components/checkout/Success.vue' /* webpackChunkName: "components/checkout-success" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSuccessBox = () => import('../../components/checkout/SuccessBox.vue' /* webpackChunkName: "components/checkout-success-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutTranssmart = () => import('../../components/checkout/Transsmart.vue' /* webpackChunkName: "components/checkout-transsmart" */).then(c => wrapFunctional(c.default || c))
export const ContactAdvice = () => import('../../components/contact/Advice.vue' /* webpackChunkName: "components/contact-advice" */).then(c => wrapFunctional(c.default || c))
export const ContactSocialLinks = () => import('../../components/contact/SocialLinks.vue' /* webpackChunkName: "components/contact-social-links" */).then(c => wrapFunctional(c.default || c))
export const ContentsAssembly = () => import('../../components/contents/Assembly.vue' /* webpackChunkName: "components/contents-assembly" */).then(c => wrapFunctional(c.default || c))
export const ContentsBlocks = () => import('../../components/contents/Blocks.vue' /* webpackChunkName: "components/contents-blocks" */).then(c => wrapFunctional(c.default || c))
export const FaqQuestion = () => import('../../components/faq/Question.vue' /* webpackChunkName: "components/faq-question" */).then(c => wrapFunctional(c.default || c))
export const FormAddress = () => import('../../components/form/Address.vue' /* webpackChunkName: "components/form-address" */).then(c => wrapFunctional(c.default || c))
export const FormForgotPassword = () => import('../../components/form/ForgotPassword.vue' /* webpackChunkName: "components/form-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../components/form/Login.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormPromocode = () => import('../../components/form/Promocode.vue' /* webpackChunkName: "components/form-promocode" */).then(c => wrapFunctional(c.default || c))
export const FormRegister = () => import('../../components/form/Register.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../../components/form/ResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormReview = () => import('../../components/form/Review.vue' /* webpackChunkName: "components/form-review" */).then(c => wrapFunctional(c.default || c))
export const FormModalReview = () => import('../../components/form/modalReview.vue' /* webpackChunkName: "components/form-modal-review" */).then(c => wrapFunctional(c.default || c))
export const ModalAddProductReview = () => import('../../components/modal/AddProductReview.vue' /* webpackChunkName: "components/modal-add-product-review" */).then(c => wrapFunctional(c.default || c))
export const ModalAddToCart = () => import('../../components/modal/AddToCart.vue' /* webpackChunkName: "components/modal-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ModalAddress = () => import('../../components/modal/Address.vue' /* webpackChunkName: "components/modal-address" */).then(c => wrapFunctional(c.default || c))
export const ModalGift = () => import('../../components/modal/Gift.vue' /* webpackChunkName: "components/modal-gift" */).then(c => wrapFunctional(c.default || c))
export const ModalIngredients = () => import('../../components/modal/Ingredients.vue' /* webpackChunkName: "components/modal-ingredients" */).then(c => wrapFunctional(c.default || c))
export const ModalProductSizeSelection = () => import('../../components/modal/ProductSizeSelection.vue' /* webpackChunkName: "components/modal-product-size-selection" */).then(c => wrapFunctional(c.default || c))
export const ModalStock = () => import('../../components/modal/Stock.vue' /* webpackChunkName: "components/modal-stock" */).then(c => wrapFunctional(c.default || c))
export const OrderItemDetail = () => import('../../components/order/ItemDetail.vue' /* webpackChunkName: "components/order-item-detail" */).then(c => wrapFunctional(c.default || c))
export const OrderLine = () => import('../../components/order/Line.vue' /* webpackChunkName: "components/order-line" */).then(c => wrapFunctional(c.default || c))
export const OrderWithoutAccount = () => import('../../components/order/WithoutAccount.vue' /* webpackChunkName: "components/order-without-account" */).then(c => wrapFunctional(c.default || c))
export const ProductBadge = () => import('../../components/product/Badge.vue' /* webpackChunkName: "components/product-badge" */).then(c => wrapFunctional(c.default || c))
export const ProductDiscountLabels = () => import('../../components/product/DiscountLabels.vue' /* webpackChunkName: "components/product-discount-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductLabel = () => import('../../components/product/Label.vue' /* webpackChunkName: "components/product-label" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceSlider = () => import('../../components/product/PriceSlider.vue' /* webpackChunkName: "components/product-price-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductReview = () => import('../../components/product/Review.vue' /* webpackChunkName: "components/product-review" */).then(c => wrapFunctional(c.default || c))
export const ProductTags = () => import('../../components/product/Tags.vue' /* webpackChunkName: "components/product-tags" */).then(c => wrapFunctional(c.default || c))
export const ProductTile = () => import('../../components/product/Tile.vue' /* webpackChunkName: "components/product-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantOptions = () => import('../../components/product/VariantOptions.vue' /* webpackChunkName: "components/product-variant-options" */).then(c => wrapFunctional(c.default || c))
export const SkeletonBrandList = () => import('../../components/skeleton/BrandList.vue' /* webpackChunkName: "components/skeleton-brand-list" */).then(c => wrapFunctional(c.default || c))
export const StoreCard = () => import('../../components/store/Card.vue' /* webpackChunkName: "components/store-card" */).then(c => wrapFunctional(c.default || c))
export const StoreGallery = () => import('../../components/store/Gallery.vue' /* webpackChunkName: "components/store-gallery" */).then(c => wrapFunctional(c.default || c))
export const StoreInfo = () => import('../../components/store/Info.vue' /* webpackChunkName: "components/store-info" */).then(c => wrapFunctional(c.default || c))
export const StoreOpeningTimes = () => import('../../components/store/OpeningTimes.vue' /* webpackChunkName: "components/store-opening-times" */).then(c => wrapFunctional(c.default || c))
export const StoreSearchInput = () => import('../../components/store/SearchInput.vue' /* webpackChunkName: "components/store-search-input" */).then(c => wrapFunctional(c.default || c))
export const StoreSearchPanel = () => import('../../components/store/SearchPanel.vue' /* webpackChunkName: "components/store-search-panel" */).then(c => wrapFunctional(c.default || c))
export const StoreSelection = () => import('../../components/store/Selection.vue' /* webpackChunkName: "components/store-selection" */).then(c => wrapFunctional(c.default || c))
export const StoreStock = () => import('../../components/store/Stock.vue' /* webpackChunkName: "components/store-stock" */).then(c => wrapFunctional(c.default || c))
export const StoreStockCard = () => import('../../components/store/StockCard.vue' /* webpackChunkName: "components/store-stock-card" */).then(c => wrapFunctional(c.default || c))
export const TransactionRecord = () => import('../../components/transaction/record.vue' /* webpackChunkName: "components/transaction-record" */).then(c => wrapFunctional(c.default || c))
export const WidgetsBenefits = () => import('../../components/widgets/Benefits.vue' /* webpackChunkName: "components/widgets-benefits" */).then(c => wrapFunctional(c.default || c))
export const WidgetsBlockLister = () => import('../../components/widgets/BlockLister.vue' /* webpackChunkName: "components/widgets-block-lister" */).then(c => wrapFunctional(c.default || c))
export const WidgetsGift = () => import('../../components/widgets/Gift.vue' /* webpackChunkName: "components/widgets-gift" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHomeHeroBanners = () => import('../../components/widgets/HomeHeroBanners.vue' /* webpackChunkName: "components/widgets-home-hero-banners" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHomeThreeBanners = () => import('../../components/widgets/HomeThreeBanners.vue' /* webpackChunkName: "components/widgets-home-three-banners" */).then(c => wrapFunctional(c.default || c))
export const WidgetsHomeTwoBanners = () => import('../../components/widgets/HomeTwoBanners.vue' /* webpackChunkName: "components/widgets-home-two-banners" */).then(c => wrapFunctional(c.default || c))
export const WidgetsNewAccount = () => import('../../components/widgets/NewAccount.vue' /* webpackChunkName: "components/widgets-new-account" */).then(c => wrapFunctional(c.default || c))
export const WidgetsThreeBannersSimple = () => import('../../components/widgets/ThreeBannersSimple.vue' /* webpackChunkName: "components/widgets-three-banners-simple" */).then(c => wrapFunctional(c.default || c))
export const WishlistLine = () => import('../../components/wishlist/Line.vue' /* webpackChunkName: "components/wishlist-line" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/footer/index.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderCartLine = () => import('../../components/app/header/CartLine.vue' /* webpackChunkName: "components/app-header-cart-line" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderCartSummary = () => import('../../components/app/header/CartSummary.vue' /* webpackChunkName: "components/app-header-cart-summary" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderCartTotal = () => import('../../components/app/header/CartTotal.vue' /* webpackChunkName: "components/app-header-cart-total" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderFavorite = () => import('../../components/app/header/Favorite.vue' /* webpackChunkName: "components/app-header-favorite" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenuBar = () => import('../../components/app/header/MenuBar.vue' /* webpackChunkName: "components/app-header-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenuBtn = () => import('../../components/app/header/MenuBtn.vue' /* webpackChunkName: "components/app-header-menu-btn" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderNavPopover = () => import('../../components/app/header/NavPopover.vue' /* webpackChunkName: "components/app-header-nav-popover" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderQuickLinks = () => import('../../components/app/header/QuickLinks.vue' /* webpackChunkName: "components/app-header-quick-links" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderSearchInput = () => import('../../components/app/header/SearchInput.vue' /* webpackChunkName: "components/app-header-search-input" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderShoppingCartBtn = () => import('../../components/app/header/ShoppingCartBtn.vue' /* webpackChunkName: "components/app-header-shopping-cart-btn" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderUserDropdown = () => import('../../components/app/header/UserDropdown.vue' /* webpackChunkName: "components/app-header-user-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/header/index.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarAccountMenu = () => import('../../components/app/sidebar/AccountMenu.vue' /* webpackChunkName: "components/app-sidebar-account-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarCatalogMenu = () => import('../../components/app/sidebar/CatalogMenu.vue' /* webpackChunkName: "components/app-sidebar-catalog-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarMainMenu = () => import('../../components/app/sidebar/MainMenu.vue' /* webpackChunkName: "components/app-sidebar-main-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarMenu = () => import('../../components/app/sidebar/Menu.vue' /* webpackChunkName: "components/app-sidebar-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSliderButton = () => import('../../components/base/range-slider/Button.vue' /* webpackChunkName: "components/base-range-slider-button" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSliderInputNumber = () => import('../../components/base/range-slider/InputNumber.vue' /* webpackChunkName: "components/base-range-slider-input-number" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSlider = () => import('../../components/base/range-slider/index.vue' /* webpackChunkName: "components/base-range-slider" */).then(c => wrapFunctional(c.default || c))
export const BlocksBaseBannerCard = () => import('../../components/blocks/base/BannerCard.vue' /* webpackChunkName: "components/blocks-base-banner-card" */).then(c => wrapFunctional(c.default || c))
export const BlocksBaseProductTile = () => import('../../components/blocks/base/ProductTile.vue' /* webpackChunkName: "components/blocks-base-product-tile" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentDefault = () => import('../../components/blocks/content/Default.vue' /* webpackChunkName: "components/blocks-content-default" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentIframeVideo = () => import('../../components/blocks/content/IframeVideo.vue' /* webpackChunkName: "components/blocks-content-iframe-video" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImage30Text70 = () => import('../../components/blocks/content/Image30Text70.vue' /* webpackChunkName: "components/blocks-content-image30-text70" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImage30TextList70 = () => import('../../components/blocks/content/Image30TextList70.vue' /* webpackChunkName: "components/blocks-content-image30-text-list70" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImage50Text50 = () => import('../../components/blocks/content/Image50Text50.vue' /* webpackChunkName: "components/blocks-content-image50-text50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImage70Text30 = () => import('../../components/blocks/content/Image70Text30.vue' /* webpackChunkName: "components/blocks-content-image70-text30" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImageCol1 = () => import('../../components/blocks/content/ImageCol1.vue' /* webpackChunkName: "components/blocks-content-image-col1" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImageCol2 = () => import('../../components/blocks/content/ImageCol2.vue' /* webpackChunkName: "components/blocks-content-image-col2" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImageCol3 = () => import('../../components/blocks/content/ImageCol3.vue' /* webpackChunkName: "components/blocks-content-image-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentImageCol4 = () => import('../../components/blocks/content/ImageCol4.vue' /* webpackChunkName: "components/blocks-content-image-col4" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentText30Image70 = () => import('../../components/blocks/content/Text30Image70.vue' /* webpackChunkName: "components/blocks-content-text30-image70" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentText50Image50 = () => import('../../components/blocks/content/Text50Image50.vue' /* webpackChunkName: "components/blocks-content-text50-image50" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentText70Image30 = () => import('../../components/blocks/content/Text70Image30.vue' /* webpackChunkName: "components/blocks-content-text70-image30" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTextList = () => import('../../components/blocks/content/TextList.vue' /* webpackChunkName: "components/blocks-content-text-list" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTextList70Image30 = () => import('../../components/blocks/content/TextList70Image30.vue' /* webpackChunkName: "components/blocks-content-text-list70-image30" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTitleCol1Text = () => import('../../components/blocks/content/TitleCol1Text.vue' /* webpackChunkName: "components/blocks-content-title-col1-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTitleCol2Text = () => import('../../components/blocks/content/TitleCol2Text.vue' /* webpackChunkName: "components/blocks-content-title-col2-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksContentTitleCol3Text = () => import('../../components/blocks/content/TitleCol3Text.vue' /* webpackChunkName: "components/blocks-content-title-col3-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicBannerLooseCol2 = () => import('../../components/blocks/dynamic/BannerLooseCol2.vue' /* webpackChunkName: "components/blocks-dynamic-banner-loose-col2" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicBannerTightCol3 = () => import('../../components/blocks/dynamic/BannerTightCol3.vue' /* webpackChunkName: "components/blocks-dynamic-banner-tight-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicBannerTightDarkCol3 = () => import('../../components/blocks/dynamic/BannerTightDarkCol3.vue' /* webpackChunkName: "components/blocks-dynamic-banner-tight-dark-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicBannerTightSimpleCol3 = () => import('../../components/blocks/dynamic/BannerTightSimpleCol3.vue' /* webpackChunkName: "components/blocks-dynamic-banner-tight-simple-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicBookingWidgets = () => import('../../components/blocks/dynamic/BookingWidgets.vue' /* webpackChunkName: "components/blocks-dynamic-booking-widgets" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicForm = () => import('../../components/blocks/dynamic/Form.vue' /* webpackChunkName: "components/blocks-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicGridProduct = () => import('../../components/blocks/dynamic/GridProduct.vue' /* webpackChunkName: "components/blocks-dynamic-grid-product" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicNewsCol3 = () => import('../../components/blocks/dynamic/NewsCol3.vue' /* webpackChunkName: "components/blocks-dynamic-news-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicSliderBanner = () => import('../../components/blocks/dynamic/SliderBanner.vue' /* webpackChunkName: "components/blocks-dynamic-slider-banner" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicSliderBrand = () => import('../../components/blocks/dynamic/SliderBrand.vue' /* webpackChunkName: "components/blocks-dynamic-slider-brand" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicSliderProduct = () => import('../../components/blocks/dynamic/SliderProduct.vue' /* webpackChunkName: "components/blocks-dynamic-slider-product" */).then(c => wrapFunctional(c.default || c))
export const ProductBlockLister = () => import('../../components/product/block/Lister.vue' /* webpackChunkName: "components/product-block-lister" */).then(c => wrapFunctional(c.default || c))
export const ProductBlockViewedProducts = () => import('../../components/product/block/ViewedProducts.vue' /* webpackChunkName: "components/product-block-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailAddToCart = () => import('../../components/product/detail/AddToCart.vue' /* webpackChunkName: "components/product-detail-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailConnections = () => import('../../components/product/detail/Connections.vue' /* webpackChunkName: "components/product-detail-connections" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailFavorite = () => import('../../components/product/detail/Favorite.vue' /* webpackChunkName: "components/product-detail-favorite" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailGallery = () => import('../../components/product/detail/Gallery.vue' /* webpackChunkName: "components/product-detail-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailGenericSelection = () => import('../../components/product/detail/GenericSelection.vue' /* webpackChunkName: "components/product-detail-generic-selection" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailGenericSelectionItem = () => import('../../components/product/detail/GenericSelectionItem.vue' /* webpackChunkName: "components/product-detail-generic-selection-item" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailPrice = () => import('../../components/product/detail/Price.vue' /* webpackChunkName: "components/product-detail-price" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailSizeSelection = () => import('../../components/product/detail/SizeSelection.vue' /* webpackChunkName: "components/product-detail-size-selection" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailVariantPrice = () => import('../../components/product/detail/VariantPrice.vue' /* webpackChunkName: "components/product-detail-variant-price" */).then(c => wrapFunctional(c.default || c))
export const ProductListerAbout = () => import('../../components/product/lister/About.vue' /* webpackChunkName: "components/product-lister-about" */).then(c => wrapFunctional(c.default || c))
export const ProductListerBanner = () => import('../../components/product/lister/Banner.vue' /* webpackChunkName: "components/product-lister-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductListerCategoryTiles = () => import('../../components/product/lister/CategoryTiles.vue' /* webpackChunkName: "components/product-lister-category-tiles" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFacetCategories = () => import('../../components/product/lister/FacetCategories.vue' /* webpackChunkName: "components/product-lister-facet-categories" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFacetDropdown = () => import('../../components/product/lister/FacetDropdown.vue' /* webpackChunkName: "components/product-lister-facet-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFacetSearch = () => import('../../components/product/lister/FacetSearch.vue' /* webpackChunkName: "components/product-lister-facet-search" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFacetWebnode = () => import('../../components/product/lister/FacetWebnode.vue' /* webpackChunkName: "components/product-lister-facet-webnode" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFavorite = () => import('../../components/product/lister/Favorite.vue' /* webpackChunkName: "components/product-lister-favorite" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFilter = () => import('../../components/product/lister/Filter.vue' /* webpackChunkName: "components/product-lister-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFilterActiveBar = () => import('../../components/product/lister/FilterActiveBar.vue' /* webpackChunkName: "components/product-lister-filter-active-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFilterFacets = () => import('../../components/product/lister/FilterFacets.vue' /* webpackChunkName: "components/product-lister-filter-facets" */).then(c => wrapFunctional(c.default || c))
export const ProductListerPageSize = () => import('../../components/product/lister/PageSize.vue' /* webpackChunkName: "components/product-lister-page-size" */).then(c => wrapFunctional(c.default || c))
export const ProductListerSortby = () => import('../../components/product/lister/Sortby.vue' /* webpackChunkName: "components/product-lister-sortby" */).then(c => wrapFunctional(c.default || c))
export const ProductLister = () => import('../../components/product/lister/index.vue' /* webpackChunkName: "components/product-lister" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailInformation = () => import('../../components/product/detail/information/Index.vue' /* webpackChunkName: "components/product-detail-information" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailInformationReviews = () => import('../../components/product/detail/information/Reviews.vue' /* webpackChunkName: "components/product-detail-information-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductListerSidebarFilter = () => import('../../components/product/lister/sidebar/Filter.vue' /* webpackChunkName: "components/product-lister-sidebar-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductListerSidebarSubFilter = () => import('../../components/product/lister/sidebar/SubFilter.vue' /* webpackChunkName: "components/product-lister-sidebar-sub-filter" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
